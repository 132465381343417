var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-5"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-text-field',{attrs:{"hide-details":"","clearable":"","label":_vm.$t('Search'),"single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-list',{staticClass:"overflow-y-auto list_height",attrs:{"flat":""}},_vm._l((_vm.organisationsForTheMenu),function(org){return _c('v-list-item',{key:org.id,attrs:{"ripple":false}},[_c('v-list-item-content',{staticClass:"pa-5"},[_c('v-list-item-title',{staticClass:"org-title",domProps:{"textContent":_vm._s(org.name)}}),_c('v-btn',{staticClass:"org-button",attrs:{"rounded":"","text":"","active-class":"org-button-active","to":{
            name: 'users',
            params: { organisationId: org.id }
          }}},[_vm._v(" Users ")]),_c('v-btn',{staticClass:"org-button",attrs:{"rounded":"","text":"","active-class":"org-button-active","to":{
            name: 'settings',
            params: { organisationId: org.id }
          }}},[_vm._v(" Settings ")]),_c('v-btn',{staticClass:"org-button",attrs:{"rounded":"","text":"","active-class":"org-button-active","to":{
            name: 'templates',
            params: { organisationId: org.id }
          }}},[_vm._v(" Templates ")])],1)],1)}),1),_c('v-divider'),_c('div',{staticClass:"d-flex px-4 pa-2 "},[_c('v-btn',{staticClass:"org-button",attrs:{"rounded":"","text":"","active-class":"org-button-active","large":"","to":{ name: 'organisation-creator' }}},[_vm._v(" "+_vm._s(_vm.$t('New Organisation'))+" "),_c('v-icon',{staticClass:" d-flex",attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-plus')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }