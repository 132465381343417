<template>
  <v-container class="main-container px-8">
    <v-row class="align-center">
      <v-col>
        <v-breadcrumbs
          :items="crumbs"
          large
          class="admin-breadcrumbs pt-30px"
        ></v-breadcrumbs>
      </v-col>
      <v-col class="d-flex justify-end">
        <router-view name="button" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="organisations-col">
        <OrganisationsCard />
      </v-col>
      <v-col>
        <router-view :key="$route.path" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>

.admin-breadcrumbs
    $breadcrumbs-item-font-size: 24px

.main-container
  max-width: 100%

.organisations-col
  max-width: 300px
</style>

<script>
import OrganisationsCard from './components/OrganisationsCard'
//import DetailsCard from './components/DetailsCard'
export default {
  components: {
    OrganisationsCard
    //DetailsCard,
  },

  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split('/')
      this.$debug('crumbs route after split: ', pathArray)
      pathArray.shift()
      this.$debug('crumbs route after shift: ', pathArray)
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? '/' + breadcrumbArray[idx - 1].path + '/' + path
            : '/' + path,
          text: this.$route.matched[idx].meta.title || path
        })
        return breadcrumbArray
      }, [])
      return breadcrumbs
    }
  }
}
</script>
