<template>
  <v-card class="elevation-5">
    <v-toolbar flat>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-text-field
        v-model="search"
        hide-details
        clearable
        :label="$t('Search')"
        single-line
      ></v-text-field>
    </v-toolbar>

    <v-divider></v-divider>

    <v-list class="overflow-y-auto list_height" flat>
      <v-list-item
        v-for="org in organisationsForTheMenu"
        :key="org.id"
        :ripple="false"
      >
        <v-list-item-content class="pa-5">
          <v-list-item-title
            class="org-title"
            v-text="org.name"
          ></v-list-item-title>
          <v-btn
            rounded
            text
            class="org-button"
            active-class="org-button-active"
            :to="{
              name: 'users',
              params: { organisationId: org.id }
            }"
          >
            Users
          </v-btn>
          <v-btn
            rounded
            text
            class="org-button"
            active-class="org-button-active"
            :to="{
              name: 'settings',
              params: { organisationId: org.id }
            }"
          >
            Settings
          </v-btn>
          <v-btn
            rounded
            text
            class="org-button"
            active-class="org-button-active"
            :to="{
              name: 'templates',
              params: { organisationId: org.id }
            }"
          >
            Templates
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />
    <div class="d-flex px-4 pa-2 ">
      <v-btn
        rounded
        text
        class="org-button"
        active-class="org-button-active"
        large
        :to="{ name: 'organisation-creator' }"
      >
        {{ $t('New Organisation') }}
        <v-icon class=" d-flex" color="primary" v-text="'mdi-plus'"></v-icon>
      </v-btn>

      <!-- Not Used yet
    </v-div>
    <v-divider />
    <v-div class="d-flex px-4 pa-2 justify-space-between">
      <v-btn rounded text class="org-button" active-class="org-button-active">
        Settings
      </v-btn>
      <v-icon class=" d-flex" color="primary" v-text="'mdi-settings'"></v-icon>
    --></div>
  </v-card>
</template>

<style lang="sass" scoped>
$btn-text-transform: capitalize !default

.org-button-active
  justify-content: right
  text-transform: capitalize
  font-size: 18px
  font-style: normal
  font-weight: normal
  color: #ca005d !important

.org-button
  justify-content: space-between
  text-transform: capitalize
  font-size: 18px
  font-style: normal
  font-weight: normal
  color: #828282
  letter-spacing: normal
  width: 100%

.org-title
  font-weight: bold
  font-size: 18px

.settings-text
  font-size: 18px

.list_height
  max-height: 60vh
</style>

<script>
import _ from 'lodash'

export default {
  data: () => ({
    searchText: null,
    card: false
  }),

  computed: {
    organisationsForTheMenu() {
      let me = this
      return _.filter(
        this.$store.getters['organisations/organisations'],
        function(o) {
          if (me.search == null) return true
          return o.name.toUpperCase().indexOf(me.search.toUpperCase()) != -1
        }
      )
    },
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.searchText = value
      }
    }
  }
}
</script>

/* { text: 'Users' }, { text: 'Campaigns'}, { text: 'Settings'}, */
