<template>
  <v-card class="elevation-5">
    <v-data-table
      :items="campaigns"
      hide-default-footer
      class="campaign-table"
      :class="type"
      item-key="name"
      disable-pagination
    >
      <template v-slot:header>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Created</th>
            <th>Modified</th>
            <th>Schedule</th>
            <th>Views</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <!-- TODO: Row function to single element, MUST NOT override action button function -->
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td @click="openCampaign(item.id)">
              {{ item.client }} / {{ item.name }}
            </td>
            <td @click="openCampaign(item.id)">{{ getType(item) }}</td>
            <td @click="openCampaign(item.id)">
              {{ formatDate(item.created_at) }}
            </td>
            <td @click="openCampaign(item.id)">
              {{ formatModified(item.updated_at, item.updated_by) }}
            </td>
            <td @click="openCampaign(item.id)">
              <!-- TODO: This shows wrong end date if user edit end day on published campaign? -->
              {{ formatSchedule(item.start_date, item.end_date) }}
            </td>
            <td @click="openCampaign(item.id)">
              {{ item.launch_count == null ? 0 : item.launch_count }}
              / {{ item.settings.total_impressions }}
            </td>
            <td
              :class="getStateClass(item.state)"
              @click="openCampaign(item.id)"
            >
              <v-btn
                v-if="item.suite_state === 3"
                class="live"
                small
                color="green"
              >
                {{ $t('Live') }}
              </v-btn>
              <span v-else>
                {{ $capitalize(item.state) }}
              </span>
            </td>
            <td
              class=" align-center "
              style="min-height: -webkit-fill-available"
            >
              <!-- TODO: How to intercept @click="openCampaign" from the <tbody>? -->
              <v-row class="justify-end">
                <v-btn
                  v-if="
                    (item.state === 'started' || item.state === 'published') &&
                      item.suite_state === 3
                  "
                  class="ma-1"
                  small
                  outlined
                  @click="campaignAction(item, 'pause')"
                >
                  {{ $t('Pause') }}
                </v-btn>
                <v-btn
                  v-if="item.state === 'stopped' && item.suite_state === 4"
                  class="ma-1"
                  small
                  color="green"
                  outlined
                  @click="campaignAction(item, 'start')"
                >
                  {{ $t('Start') }}
                </v-btn>
                <v-btn
                  class="duplicate ma-1"
                  small
                  outlined
                  @click="duplicateCampaign(item.id)"
                >
                  {{ $t('Duplicate') }}
                </v-btn>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>
<style lang="sass" scoped>
.campaign-table
  padding: 24px

  tbody tr:nth-of-type(odd)
    background-color: #EFEFEF

  td
    border: none !important
    &:first-child
      border-top-left-radius: 6px
      border-bottom-left-radius: 6px
    &:last-child
      border-top-right-radius: 6px
      border-bottom-right-radius: 6px
      width: 212px
  tr:hover
    cursor: pointer

  thead
    tr
      th
        font-weight: 300
        font-size: 16px !important
        border: none !important

.duplicate
  margin-left: 10px

.live
  color: #FFF !important
  font-weight: bold
  text-align: center

.paused
  color: #F99A43
  text-transform: capitalize

.draft
  color: #F99A43
  text-transform: capitalize

.past
  opacity: 0.6
  button
    border-color: black
    span
      color: black
</style>
<script>
// TODO: Chechk functionality and styling with other cases when we get those
import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    timeFilter: {
      default: null,
      type: String,
      required: false
    }
  },
  computed: {
    campaigns() {
      let me = this
      return _.filter(this.$store.getters['campaigns/' + this.type], function(
        o
      ) {
        if (me.timeFilter == null) return true
        if (me.timeFilterMoment == false) return true
        return moment(o.end_date).isAfter(me.timeFilterMoment)
      })
    },
    timeFilterMoment() {
      let me = this
      if (me.timeFilter == null || me.timeFilter == 'All') return false
      else if (me.timeFilter == 'Last 30 days')
        return moment().subtract(1, 'months')
      else if (me.timeFilter == 'Last 90 Days')
        return moment().subtract(3, 'months')
      else if (me.timeFilter == 'Past Half a Year')
        return moment().subtract(6, 'months')
      else if (me.timeFilter == 'Past Year')
        return moment().subtract(1, 'years')
      return false
    }
  },
  watch: {
    // This would be called anytime the value of timeFilter changes
    timeFilter(newValue) {
      this.timeFilter = newValue
    }
  },
  methods: {
    async openCampaign(id) {
      let me = this
      await me.$redirect({
        name: 'campaign-editor',
        params: { campaignId: id, pageOfOrigin: 'dashboard' }
      })
    },
    async duplicateCampaign(id) {
      let me = this
      try {
        await me.$store.dispatch('campaigns/duplicateCampaign', id)
        this.$toast({
          type: 'green',
          message: `Campaign duplicated`
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
        }
      }
    },

    async campaignAction(item, action) {
      let me = this
      if (action === 'pause') {
        try {
          await me.$store.dispatch('campaigns/pauseCampaign', item.id)
          this.$toast({
            type: 'orange darken-2',
            message: `Campaign Paused`
          })
        } catch (err) {
          if (err.message !== 'cancel') {
            me.$error(err)
          }
        }
      } else {
        try {
          await me.$store.dispatch('campaigns/startCampaign', item.id)
          this.$toast({
            type: 'green',
            message: `Campaign Started`
          })
        } catch (err) {
          if (err.message !== 'cancel') {
            me.$error(err)
          }
        }
      }
    },

    duplicate(event) {
      event.stopPropagation()
      this.$toast({
        type: 'orange darken-2',
        message: `Duplicating campaigns is currently under development.`
      })
    },
    getType(campaign) {
      var typeText = ''
      if (campaign.type === 'switch-in') typeText = 'SI'
      if (campaign.is_interactive) typeText = typeText + ' / Interactive'
      return typeText
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    formatSchedule(start, end) {
      if (!start) return 'Start date not set'
      if (!end) return 'End date not set'
      return `${moment(start).format('DD.MM.YYYY')} -
        ${moment(end).format('DD.MM.YYYY')}`
    },
    formatModified(updateDate, updateBy) {
      if (updateBy) return moment(updateDate).format('DD.MM.YYYY')
      if (!updateBy) return '-'
    },
    getButtonType(state) {
      if (state === 'past') return 'Duplicate'
      if (state === 'draft') return 'Start'
      if (state === 'running') return 'Running'
    },
    getStateClass(state) {
      if (state === 'draft') return 'draft'
      if (state === 'paused') return 'paused'
      if (state === 'live') return 'live'
    }
  }
}
</script>
