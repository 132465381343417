<template>
  <v-container>
    <v-row class="mt-4 mb-2">
      <v-col class="d-md-none"></v-col>
      <v-col
        sm="12"
        order="last"
        md="4"
        order-md="first"
        class="d-flex align-center"
      >
        <span class="headline">
          {{ $t('Ongoing campaigns / %s', ongoingLength) }}
        </span>
      </v-col>
      <v-col class="d-flex align-center justify-center">
        <DashboardTabs></DashboardTabs>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <NewCampaignButton></NewCampaignButton>
      </v-col>
    </v-row>

    <CampaignListWidget :type="'ongoing'"></CampaignListWidget>

    <v-row class="mt-7 mb-3">
      <v-col class="d-flex align-center">
        <span class="headline">
          {{ $t('Drafted campaigns / %s', draftedLength) }}
        </span>
      </v-col>
    </v-row>

    <CampaignListWidget :type="'draft'"></CampaignListWidget>

    <v-row class="mt-7 mb-3 ">
      <v-col class="d-flex align-center">
        <span class="headline">
          {{ $t('Past campaigns / %s', pastLength) }}
        </span>
      </v-col>

      <v-col style="max-width: 220px;">
        <v-select
          v-model="select"
          style="height: 50px;"
          :items="filterItems"
          solo
        ></v-select>
      </v-col>
    </v-row>

    <CampaignListWidget
      :type="'past'"
      :time-filter="select"
    ></CampaignListWidget>
  </v-container>
</template>
<script>
import DashboardTabs from './components/DashboardTabs'
import NewCampaignButton from './components/NewCampaignButton'
import CampaignListWidget from './components/CampaignListWidget'
import moment from 'moment'
import _ from 'lodash'

export default {
  components: {
    DashboardTabs,
    NewCampaignButton,
    CampaignListWidget
  },
  data: () => ({
    select: 'Last 30 days',
    filterItems: [
      'Last 30 days',
      'Last 90 Days',
      'Past Half a Year',
      'Past Year',
      'All'
    ]
  }),

  computed: {
    ongoingLength() {
      return this.$store.getters['campaigns/ongoing'].length
    },
    draftedLength() {
      return this.$store.getters['campaigns/draft'].length
    },

    timeFilterMoment() {
      let me = this
      if (me.select == null || me.select == 'All') return false
      else if (me.select == 'Last 30 days')
        return moment().subtract(1, 'months')
      else if (me.select == 'Last 90 Days')
        return moment().subtract(3, 'months')
      else if (me.select == 'Past Half a Year')
        return moment().subtract(6, 'months')
      else if (me.select == 'Past Year') return moment().subtract(1, 'years')
      return false
    },
    campaigns() {
      let me = this
      return _.filter(this.$store.getters['campaigns/past'], function(o) {
        if (me.select == null) return true
        if (me.timeFilterMoment == false) return true
        return moment(o.end_date).isAfter(me.timeFilterMoment)
      })
    },
    pastLength() {
      return this.campaigns.length
    }
  }
}
</script>
